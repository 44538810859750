<template>
    <div class="term-detail" id="term-detail">
      <div class="term-inner">
        <div class="vc_column-inner"><div class="wpb_wrapper"><div class="wpb_text_column"><div class="wpb_wrapper"><h3 class="align-center">第一章 総則</h3>
        <h4 class="subtitle">第１条（適用範囲）</h4>
        <p class="paragraph">プロンテストシリーズ利用規約は、（以下「本規約」といいます）第2条（１）に規定する本サービスのご利用者（以下「ユーザー」といいます）並びに第２条（２）に規定する本サービスのご契約者（以下「契約者」といいます）が株式会社プロンテスト（以下「当社」といいます）が提供する同第2条（１）に規定する語学学習アプリケーションサービス（以下「本サービス」といいます）を利用する際に適用されるものとします。本サービスをご利用いただく全てのユーザーおよび契約者は、本サービス利用にあたり、本規約に同意いただいたものとみなされます。</p>
        <h4 class="subtitle">第2条（定義）</h4>
        <p class="paragraph">本規約において使用する用語の意味は、次の各号に定める通りとします。<br>
        （１）「本サービス」とは、当社がウェブサイト上で運営・提供する「発音特訓パック」「PRONTESTCALL」等の語学学習アプリケーションサービス及びそれに付随して追加可能なオプション機能、メール配信、利用サポート窓口運営その他各種サービスを総称していい、本サービスをご自身でご利用になる全ての方（以下「ユーザー」といいます）が次の方法により利用することが可能なサービスをいいます。なお、本サービスの詳細については、当社が提示する資料等に従うものとします。</p>
        <ul>
        <li>ユーザーがウェブサイトから本サービスへアクセスし、本規約を確認した上で、自身の学習目的で利用すること。</li>
        <li>ユーザーがウェブサイトから本サービスへアクセスし、本規約を確認した上で、自身の端末へプログラムをダウンロードまたはインストールし、自身の学習目的で利用すること。</li>
        <li>本サービスの導入を検討するものが、その目的のため、当社の許諾を得た上で、限定された期間、本サービスを利用すること。</li>
        </ul>
        <p class="paragraph">（２）「ユーザー」とは、次に挙げる本サービスのご利用者の総称とします。<br>
        ①「個人ユーザー」：当社のウェブサイト上で本規約に同意の上、本サービスをご購入し、個人で本サービスを利用されるユーザーをいいます。<br>
        ②「法人ユーザー」：本サービスの利用を目的に法人・団体等が当社と締結した契約に基づき、本サービスを利用されるユーザーをいいます。法人ユーザーは本規約に従って本サービスを利用するものとし、当該法人・団体等が当社または販売店と締結した契約に基づき指示を行う場合には、当該指示に従って本サービスを利用するものとします。ただし、当該指示がサービス利用契約と矛盾する場合は、サービス利用契約の規定が優先されます。<br>
        ③「試用ユーザー」：本サービスの利用を検討されるお客様が当社の承諾を得て本サービス利用する場合、当該使用目的で利用されるユーザーをいいます。（３）「契約者」とは、本サービスに関するサービス利用契約を当社もしくは当社が指定する販売店と締結した法人・団体等、または当社のウェブサイト上で本規約に同意した個人ユーザーを意味します。<br>
        （４）「サービス利用契約」とは、本サービス利用に関して契約者が同意した本規約、または契約者が、当社または当社が指定する販売店と締結した契約書や注文書等をいいます。<br>
        （５）「販売店」とは、本サービスを再販売する者をいいます。</p>
        <p>&nbsp;</p>
        <h4 class="subtitle">第３条（規約の適用）</h4>
        <ol>
        <li>本規約は、本サービスを利用する契約者及び販売店と当社間に適用されるものとします。</li>
        <li>当社が本サービスで提示する別途の規約および諸注意（以下「諸注意等」といいます。）が存在する場合には、諸注意等はそれぞれ本規約の一部を構成するものとします。</li>
        <li>当社と契約者が個別の利用契約およびその他の利用条件を別途定めている場合には、それらは本規約に優先して適用されるものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h4 class="subtitle">第４条（規約の改定）</h4>
        <ol>
        <li>当社は、本規約又は本サービスの内容を必要に応じて変更することができるものとし、ユーザーに事前の通知をすることなく本規約および諸注意等を変更（追加・削除を含みます）することができるものとします。<br>
        その場合、当社は、合理的と判断される方法により、当該変更の旨を契約者及び販売店へ事前に通知するものとします。</li>
        <li>本規約改定後は、改定された本規約が本サービス利用に係わる契約者と販売店、ユーザーに適用されるものとします。本規約の変更後に当該当事者が本サービスを利用した場合、本規約の変更は同意されたものとみなします。</li>
        </ol>
        <p>&nbsp;</p>
        <h4 class="subtitle">第５条（権利義務の譲渡禁止）</h4>
        <ol>
        <li>契約者及びユーザーは、当社の事前の書面による承諾なく、本サービスの利用に関する一切の権利、義務を第三者に譲渡、承継し、又は担保に供さないものとします。</li>
        <li>契約者及びユーザーは、本サービスおよび本サービスに関連して発生する著作物、システム等についての著作権その他の知的財産権は、当社または当社に使用許諾を行った第三者に帰属するものであることを確認し、いかなる目的であれ転載、複製、送信、翻訳・翻案、改変・追加等の一切の使用行為を行わないものとします。</li>
        <li>本条の規定に違反して紛争が生じた場合、契約者またはユーザーまたは両者は、自己の費用と責任において、当該紛争を解決するとともに、当社および第三者に一切の損害を与えないものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <h4 class="subtitle">第６条（準拠法）</h4>
        <p class="paragraph">本規約及びサービス利用契約書の成立・効力・履行及び解釈に関しては、日本法が適用されるものとします。</p>
        <p>&nbsp;</p>
        <h4 class="subtitle">第７条（合意管轄）</h4>
        <p class="paragraph">契約者及びユーザーと当社で起訴の必要が生じた場合には、東京地方裁判所を第一審の専属的管轄裁判所とします。</p>
        <p>&nbsp;</p>
        <h4 class="subtitle">第８条（協議）</h4>
        <p class="paragraph">本規約に定めのない事項及び本規約の各条項の解釈に疑義が生じた事項に関しては、契約者及びユーザーと当社にて、誠意を持って協議し解決するものとします。</p>
        <h4 class="subtitle">第９条（無効規定の分離）</h4>
        <p class="paragraph">本規約のいずれかにの条項が何らかの理由で違法、無効または執行不能である旨、管轄権を有する裁判所に判示された場合、残りの条項は何らの影響を受けず、また損なわれることなく、有効に存続するものとします。</p>
        <p>&nbsp;</p>
        <h3 class="align-center">第２章 契約の締結等</h3>
        <h4 class="subtitle">第１０条（利用料金及び支払方法）</h4>
        <p class="paragraph">契約者は、サービス利用契約に基づき本サービスの利用料金を支払うものとします。また、個人ユーザーは、当社ウェブサイト上に明記される「特定商取引法に基づく表示」に従って本サービスの利用料金をお支払いいただきます。</p>
        <p>&nbsp;</p>
        <h4 class="subtitle">第１１条（契約者によるサービス利用契約の解約）</h4>
        <ol>
        <li>契約者は、サービス利用契約の有効期間中に利用契約に基づいて解約を希望する場合には、別途当社が提示する方法にてサービス利用契約の解約を申請することにより、これを解約することができるものとします。個人ユーザーが本サービスの利用を停止する場合は、当社ウェブサイト上に明記される「特定商取引法に基づく表示」に従って本サービスの利用停止手続きを行うものとし、当該手続きにより、本規約は解約されます。</li>
        <li>前項に基づき契約者がサービス利用契約書を解約した場合には、既に契約者より当社または販売店へ支払われた利用料金は、一切返還されないものとします。</li>
        <li>本条第１項に基づく利用契約の解約があった時点において未払いの利用料金又は支払遅延損害金がある場合には、契約者は当社または販売店が別途定める期日までにこれを支払うものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <h4 class="subtitle">第１２条（当社によるサービス利用契約書の解約）</h4>
        <ol>
        <li>当社は、契約者が次の各号のいずれかに該当すると判断した場合には、契約者への事前の通知若しくは催告を要することなくサービス利用契約書の全部若しくは一部を解約することができるものとします。
        <ol class="bracket">
        <li>サービス利用契約書その他の通知内容等に虚偽記入又は記入漏れがあった場合</li>
        <li>自ら振り出した手形または小切手の不渡り処分を受ける等、支払停止または支払不能の状態に陥った場合</li>
        <li>監督官庁より営業の取消、停止等の処分を受けた場合</li>
        <li>第三者より差押、仮差押、仮処分を受けた場合</li>
        <li>破産、特別清算、民事再生手続、会社更生手続の申し立てを受けもしくは自ら申し立てるとき、または解散決議をする場合</li>
        <li>合併、会社分割または営業の重要な部分を譲渡した場合</li>
        <li>本規約等に違反し、当社または販売店、またはそれらと契約のある弁護士、債権回収代行会社等からの違反の是正を催告した後、合理的な期間内に是正されない場合</li>
        <li>契約者またはユーザーが、反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動標榜ゴロ、特殊知能暴力集団等又はこれらに準じる者）に該当することが判明した場合</li>
        <li>その他当事者間の信頼関係を著しく損ない、本契約を継続しがたい重大な事由が発生した場合に本項に基づき当社とのサービス利用契約を解約したときは、既に契約者より当社または販売店へ支払われた利用料金は、一切返還されないものとします。</li>
        </ol>
        </li>
        <li>本条第１項に基づくサービス利用契約の解約があった時点において、未払いの利用料金等又は遅延損害金がある場合には、契約者は、当社または販売店、またはそれらと契約のある弁護士、債権回収代行会社等からの通知により定める期日までにこれを支払うものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <h4 class="subtitle">第１３条（契約終了後の処理）</h4>
        <ol>
        <li>契約者は、サービス利用契約が終了した場合には、本サービスの利用にあたって、契約者が保持する当社または販売店との機密情報及び、法人ユーザーの個人情報を、契約者の責任で消去又は廃棄等適切に処理するものとします。</li>
        <li>法人ユーザーは、サービス利用契約が終了し、ユーザー個人としてサービスの継続利用を行う意思がない場合には、本サービスの提供にあたって個人の端末へインストールまたはダウンロードしたプログラムを削除するものとし、当社から付与された会員専用ページ（以下「ポータルサイト」といいます）及びそこに記録された当該ユーザーの個人情報は、当該ユーザー個人の責任により別途解約手続きを行うことで消去されるものとします。</li>
        <li>個人ユーザーは、本サービス契約が終了した場合、本サービスの提供にあたって個人の端末へインストールまたはダウンロードしたプログラムを自己裁量で削除するものとします。当社から付与されたポータルサイト及びそこに記録された当該ユーザーの個人情報は、当該ユーザー個人の責任により別途解約手続きを行うことで消去されるものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <h3 class="align-center">第３章 契約者の義務等</h3>
        <h4 class="subtitle">第１４条（知的財産権等）</h4>
        <ol>
        <li>本サービスにかかるデザイン、データベース、プログラム、ロゴ等に関する著作権、商標権その他一切の権利は、当社または当社へ利用許諾を行った第三者に帰属するものとします。契約者は、本サービス上で認められた範囲を超えてこれを複製、販売、頌布、改変、翻案、翻訳、公衆送信を行うことはできません。</li>
        <li>本サービスを利用することにより生成される情報を特定の個人を識別できないように加工することで得られる情報（以下、匿名加工情報という）の所有権及び利用権は全て当社に帰属します。契約者及びユーザーは、ユーザー本人が自身の個人情報を利用する場合を除き、いかなる方法であっても、当社の許諾なく、これらの情報及び情報を編集した情報を収集・編集・公開・流布することはできません。</li>
        </ol>
        <p>&nbsp;</p>
        <h4 class="subtitle">第１５条（禁止事項）</h4>
        <ol>
        <li>契約者及びユーザーは、本サービスの利用にあたり、次の各号のいずれかに該当する行為を行わないものとし、次の各号のいずれかに該当する行為がなされたことを知った場合又は該当する行為がなされるおそれがあると判断した場合には、直ちに当社及び販売店へ通知するものとします。
        <ol class="bracket">
        <li>法律、命令、処分、その他の規制に違反する行為</li>
        <li>犯罪行為を惹起又は助長する行為その他犯罪行為に結びつく行為</li>
        <li>第三者が有する著作権、商標権、肖像権、その他の権利・利益を侵害または侵害を助長する行為</li>
        <li>有害なプログラム等の送信等を行い、当社及び販売店の業務に影響を与える行為</li>
        <li>当社及び販売店、本サービスのユーザー（契約者の認知しないものも含む）を誹謗中傷し、又はその名誉、信用を害する行為</li>
        <li>虚偽情報、事実誤認を生じさせる情報等を書面、口頭、電磁的記録等の手段を問わず、公示、掲載、放送、流布するまたはそれらを助長する行為</li>
        <li>当社が本サービス上で提供する各種サービスを不正の目的をもって利用する行為（本サービスに発生した不具合の利用による当該行為を含む。）</li>
        <li>当社の承諾なく、営利を目的とした本サービスに関係する情報提供の行為</li>
        <li>当社の許諾なく、ユーザーの個人情報または匿名加工情報を不特定多数へ書面、口頭、電磁的記録等の手段を問わず、公示、掲載、放送、流布するまたはそれらを助長する行為</li>
        <li>その他、公序良俗に反することが明らかな行為</li>
        </ol>
        </li>
        <li>当社は、契約者またはユーザーが前項各号のいずれかに該当する行為を行った又はその恐れがあることを知った場合には、事前に契約者またはユーザーに通知することなく、本サービスの全部又は一部の提供を一時中断し、又は前項各号に該当する行為に関連する情報を本サービスから削除することができるものとします。</li>
        <li>法人・団体等の契約者は、本サービスを第２条に定義する目的及び方法により法人ユーザーに適切に利用させるものとし、本条第１項各号のいずれかに該当する行為を行わせないものとします。なお、法人・団体等の契約者は、法人ユーザーが該当する行為を行った場合には、たとえその事実について不知であった場合でも、前項の規定を準用するものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <h3 class="align-center">第４章 本サービスの運営</h3>
        <h4 class="subtitle">第１６条（善管注意義務）</h4>
        <p class="paragraph">当社及び販売店は、本サービスの利用期間中、善良なる管理者の注意をもって本サービスを契約者及びユーザーに提供するものとします。ただし、サービス利用契約書に別段の定めがあるときは、この限りではないものとします。</p>
        <p>&nbsp;</p>
        <h4 class="subtitle">第１７条（動作環境）</h4>
        <p class="paragraph">本サービスは、下記に規定される推奨動作環境において動作することを確認しております。推奨動作環境以上でなかったとしても、ご利用いただける場合もございますが、動作や表示に不具合を生じる可能性がございます。</p>
        <table>
        <tbody>
        <tr>
        <td width="200">OS</td>
        <td width="500">Windows10</td>
        </tr>
        <tr>
        <td>ブラウザ</td>
        <td>Chrome (バージョン79以降)</td>
        </tr>
        <tr>
        <td>対応端末</td>
        <td>PC</td>
        </tr>
        <tr>
        <td>動作スペック</td>
        <td>CPU:4コア1.6Ghz以上<br>
        メモリ：４GB以上</td>
        </tr>
        <tr>
        <td>マイク種別</td>
        <td>USBマイク</td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <h4 class="subtitle">第１８条（本サービスの利用環境）</h4>
        <ol>
        <li>契約者は、本サービスの利用に当たっては、本規約または、本サービスのご利用ガイドページにおいて指定する端末等の環境（以下「対応端末」といいます）にて利用するものとします。対応端末以外の環境では本サービスの全部又は一部が利用できない場合があります。当社は契約者またはユーザーが本サービスを利用している間に、事前の予告なく対応端末を変更する場合があります。環境が原因で本サービスの全部又は一部の利用に制限が生じる場合でも、それにより生じるいかなる損失も、当社及び販売店は何ら責任を負いません。ただし、サービス利用契約書に別段の定めがあるときは、この限りではないものとします。</li>
        <li>ユーザーは本サービスを利用するにあたって、本サービスが音声を取り扱うサービスであることを考慮し、本サービスを利用する際には周囲の環境に配慮し、騒音をさけ、静かな環境で練習する必要があることを理解するものとします。著しい騒音が発生している環境や、バスや電車内などの公共交通機関内など、一般通念上、音声を発する上で適さない環境での利用により生じる一切の損害及び紛争については、ユーザーは自己の費用と責任において当該紛争を解決するとともに、当社及び第三者に一切の責任を求めないものとします。</li>
        <li>契約者及びユーザーは、本サービスを利用するために必要なパーソナルコンピュータ、タブレットPC、スマートフォン等の全ての機器及び、第三者の提供するブラウザアプリケーションのインストール及び設定等を、自らの費用と責任において整え、本サービスが利用可能な状態に置くものとします。また、本サービスの利用にあたって必要な通信費用等はユーザーが負担するものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <h4 class="subtitle">第１９条（個人情報の取扱）</h4>
        <ol>
        <li>本サービスを提供するにあたり、契約者がユーザーの個人情報を取得した場合には、当社の提示する本サービスに関するプライバシーポリシーに従うものとします。</li>
        <li>前項に基づきユーザーまたは第三者より、契約者に対して本サービスの個人情報の取り扱いに関する問い合わせ、クレーム等があった場合には、契約者は自らの責任と費用により、これに対応するものとします。ただし、当該問合せ等が当社または販売店に帰すべき事由によるときには、当然に当事者がこれに対応するものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <h4 class="subtitle">第２０条（サービスの一時中断）</h4>
        <ol>
        <li>当社は、契約者またはユーザーが本規約に違反した場合には、事前に通知することなく、かかる違反が是正されるまでの間、当該契約者に対して本サービスの全部又は一部の利用を一時中断、停止することができるものとします。なお、この措置によっても契約者の本サービスの利用料金等に何らの影響を及ぼすものでもなく、契約者は、既に当社または販売店に対して支払われた利用料金の返還等の義務を一切負うものではないことに同意するものとします。</li>
        <li>次の各号のいずれかに該当する場合は、当社は一時的に本サービスの全部又は一部の提供を中断、停止することができるものとし、これにより契約者に生じた損害についてその賠償の責を負わないものとします。
        <ol class="bracket">
        <li>サーバダウン、クラッキングにより運用を継続することが困難となった場合</li>
        <li>保守、メンテナンスを行う場合</li>
        <li>火災・停電等の事故、地震・洪水等の天災、戦争・暴動・労働争議等の不測の事態により、本サービスの提供ができなくなった場合</li>
        <li>その他、当社が運用上又は技術上、本サービスの一時中断が必要であると判断した場合</li>
        </ol>
        </li>
        </ol>
        <h4 class="subtitle">第２１条（本サービスの変更、中止）</h4>
        <ol>
        <li>当社は本サービスの内容を必要に応じて変更、修正することができるものとします。この場合、当社自らが適当と判断する方法によりこれを契約者に通知、告知するものとします。</li>
        <li>当社が本サービスを中止する場合は、事前に販売店、契約者及びユーザーに対して、任意の手段にて通知するものとします。</li>
        </ol>
        <p>&nbsp;</p>
        <h4 class="subtitle">第２２条（免責）</h4>
        <ol>
        <li>当社は、本製品の更新版を提供する義務、および本製品に関するサポートを行う義務は負わないものとします。ただし、当社の判断により、ユーザーに本製品の更新版、製品の更新情報、サポートサービス、各種付帯サービスを、期間限定、または期間を問わず提供する場合があります。その場合、本製品の更新版についても本規約が適用されます。</li>
        <li>当社は、ユーザーが使用する機器、設備またはソフトウェアが本製品の利用に適さない場合であっても、本製品の変更、改変等を行う義務は一切負わないものとします。</li>
        <li>当社は、アクセス過多、その他予期せぬ要因に基づく本サービスの表示速度の低下や障害等によって生じたいかなる損害についても、一切責任を負わないものとします。</li>
        <li>ユーザーは、第三者が提供し本製品中を構成するソフトウェア・APIサービスが、理由の如何を問わず本サービス内において利用できなくなった場合には、本サービスが利用できなくなることを予め承諾し、本サービスを利用するものとします。なお、本サービスの利用ができなくなったことにより、ユーザーに何らかの損害が発生した場合も、当社は一切責任を負わないものとします。</li>
        <li>契約者が、本サービスにアクセスするにあたって認証を要する一部のサービスにおいて、ユーザーによる適切なパスワード設定などによって行われる認証の安全性が常に保証されるものではありません。</li>
        <li>当社はハッキング・クラッキング・その他情報漏洩リスクに対処すべく、情報セキュリティ認証を取得し、脆弱性診断を実施し対策を行うなど、本サービスを運営する上で、当該時点の技術的知見の範囲で十分と考えられる対策をとりますが、本サービスに係るシステムの第三者の攻撃に対し、完全な対策を保証するものではありません。</li>
        <li>当社は、契約者またはユーザーが本サービスを利用した場合に生じた一切の損害について、何らの責任を負わないものとします。</li>
        <li>当社は契約者またはユーザーが本サービスを利用したことによる結果的損害又は特定目的への適合性について何ら責任を負わないものとします。</li>
        <li>本サービスの利用に関連して、契約者とユーザー、他の契約者、その他第三者との間で何らかの紛争が生じた場合、当時者間で解決していただくものとし、当社は一切関知せず、なんら責任を負わないものとします。</li>
        </ol>
        <h4 class="subtitle">第２３条（契約者及びユーザーの損害賠償責任）</h4>
        <p class="paragraph">契約者及びユーザーは、本規約に違反し当社はまたは販売店に損害を与えた場合は、その損害を賠償するものとします。</p>
        <p>&nbsp;</p>
        <h4 class="subtitle">第２４条（当社の損害賠償責任）</h4>
        <p class="paragraph">当社がサービス利用契約書又は本規約に違反し契約者に損害を与えた場合は、その損害を賠償するものとします。ただし、その損害賠償額は、本サービスの利用に関して、損害が発生した期間に支払われた利用料金額を超えないものとします。</p>
        <p>&nbsp;</p>
        <h4 class="subtitle">第２５条（利用終了）</h4>
        <p class="paragraph">契約者は、本サービスの利用を終了させる場合、当社が別途規定した方法にて申請するものとします。</p>
        <p>&nbsp;</p>
        <p>施行：令和2年1月21日</p>
        <p>改訂：令和2年7月14日</p>
        </div></div></div></div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'TermsDetail',
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.term-detail {
  width: 900px;
  height: 375px;
  margin: auto;
  margin-top: 30px;
  font-size: 23px;
  border: 1px solid #707070;
  padding: 0 5px;
  border: 1px solid var(--unnamed-color-707070);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0000006E;
  border: 1px solid #707070;
  .term-inner {
    overflow-y: scroll;
    height: 370px;
    margin: 5px 0px;
    padding: 0 10px;
    .paragraph {
      text-align: left;
    }
    li {
      text-align: left;
    }
  }
  p {
    line-height: 200%;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
}
</style>